import React, { useRef, useState, useEffect } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

import { months } from '../../../constants/constants';

import { putSaleAction } from '../../../actions/formsActions';
import SalesRow from '../../partials/SaleRow/SaleRow';

import styles from './SalesYear.module.scss';

export default function SalesYear({
  content,
  provisionalContent,
  isProvisional,
  name,
  id,
}) {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const autoSaveTimer = useRef();
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [contentList, setContentList] = useState();
  const [form, setForm] = useState({});

  function saveWithTimer(data) {
    clearTimeout(autoSaveTimer.current);
    autoSaveTimer.current = setTimeout(() => putSaleAction(dispatch, id, data), 3000);
  }

  function handleChange(key, value) {
    const data = { ...form, [key]: value };
    setForm(data);
    saveWithTimer(data);
  }

  useEffect(() => {
    if (content) {
      const list = [];
      Object.entries(content).forEach(([key, value]) => {
        list.push({
          _id: `${id}-${key}`,
          key: `${key}`,
          label: months[key],
          isProvisional,
          provisional: provisionalContent && provisionalContent[key],
          unit: '€ HT',
          name,
          value: value || '',
        });
      });
      setForm({});
      setContentList(list);
    }
  }, [content]);
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>{name}</h2>
        <button
          onClick={() => setPanelIsOpen(!panelIsOpen)}
        >
          {panelIsOpen ? <RiArrowUpSLine size={28} color={'#192243'} /> : <RiArrowDownSLine size={28} color={'#192243'} />}
        </button>
      </div>
      <div
        className={styles.panel}
        style={{
          height: panelIsOpen && contentRef?.current?.clientHeight > 0
            ? contentRef.current.clientHeight + 8 : 0,
        }}>
        <div ref={contentRef}>
          {contentList && <div className={styles.content}>
            <div className={styles.labels}>
              <div className={styles.month}>
              </div>
              <div className={styles.label}>
                <p>Du territoire</p>
              </div>
              <div className={styles.label}>
                <p>Hors du territoire</p>
              </div>
            </div>
            {contentList?.map((m, i) => (
              <SalesRow
                key={m._id}
                monthData={m}
                index={i}
                handleChange={(key, value) => handleChange(key, value)}
              />
            ))}
          </div>
          }
        </div>
      </div>
    </div>
  );
}
